import { ClickableSection } from "../../shared/ClickableSection.component";
import { Headline } from "../../shared/Headline.component";
import { data } from "./resume.json";

const CareersFriend = () => {
  
  // const careers = data.map((entry) => {
  //   switch (entry.type) {
  //     case "stats":
  //       return <ClickableSection data={entry} />;
  //     // case "timeline":
  //     //   return <Timeline data={entry} />;
  //     case "headline":
  //       return <Headline data={entry} />;
  //     default:
  //       return <></>;
  //   }
  // }
  // );

  return < div className="flex flex-col items-center justify-center min-h-full">
      <div >Error 404: Friend not found. The task is to take care of my cat, it's very fast pace. </div>
      <div>How about try <div className="inline border-b-2 py-1 leading-9 rounded-sm text-purple-500 " onClick={() => {window.location.href = "/careers/soulmate";}}>soulmates</div> </div>
    </div>;
};

export default CareersFriend
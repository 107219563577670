import Home from './home/home.component';
import Resume from './resume/resume.component';
import About from './about/about.component';
import Impossible from './impossible/impossible.component';

import Careers from './careers/careers.component';
import CareersFriend from './careers.friend/careers.component';
import CareersGirlFriend from './careers.girlfriend/careers.component';
import CareersWife from './careers.wife/careers.component';
import CareersSoulmate from './careers.soulmate/careers.component';

export const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/cv",
    element: <Resume />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/impossible",
    element: <Impossible />
  },
  {
    path: "/careers",
    element: <Careers />
  },
  {
    path: "/careers/friendswtfihopeyoudidntclickthis",
    element: <CareersFriend />
  },
  {
    path: "/careers/soulmate",
    element: <CareersSoulmate />
  },
  {
    path: "/careers/wife",
    element: <CareersWife />
  },
  {
    path: "/careers/gf",
    element: <CareersGirlFriend />
  },

];
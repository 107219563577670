import { ClickableSection } from "../../shared/ClickableSection.component";
import { Headline } from "../../shared/Headline.component";
import { data } from "./data.json";

const CareersSoulmate = () => {
  
  const careers = data.map((entry) => {
    switch (entry.type) {
      case "stats":
        return <ClickableSection data={entry} />;
      // case "timeline":
      //   return <Timeline data={entry} />;
      case "headline":
        return <Headline data={entry} />;
      default:
        return <></>;
    }
  });

    return < div className="flex flex-col items-center justify-center min-h-full">
      
      <div>Could be soulmates, but check if you qualify for <div className="inline border-b-2 py-1 leading-9 rounded-sm text-purple-500 " onClick={() => {window.location.href = "/careers/gf";}}>girlfriend</div> first</div>
    </div>;
};

export default CareersSoulmate
import { ClickableSection } from "../../shared/ClickableSection.component";
import { Headline } from "../../shared/Headline.component";
import { data } from "./data.json";

const CareersGirlFriend = () => {
  return < div className="flex flex-col items-center justify-center min-h-full">      
      <div>If you have at least 5 years of experience at Crayola or you're non toxic, might as well apply for  <div className="inline border-b-2 py-1 leading-9 rounded-sm text-purple-500 " onClick={() => {window.location.href = "/careers/wife";}}>executive cat wife</div></div>
    </div>;
};

export default CareersGirlFriend
import { ClickableSection } from "../../shared/ClickableSection.component";
import { Headline } from "../../shared/Headline.component";
import { data } from "./data.json";

const Careers = () => {
  
  const careers = data.map((entry) => {
    switch (entry.type) {
      case "stats":
        {{console.log(entry.link)}}
        return <ClickableSection data={entry} link={entry.link}/>;
      // case "timeline":
      //   return <Timeline data={entry} />;
      case "headline":
        return <Headline data={entry} />;
      default:
        return <></>;
    }
  });

  return <div>{careers}</div>;
};

export default Careers
import { useRoutes } from "react-router-dom";
import { routes } from "./main/main-routing.module";

export const Router = ({setNavBarHidden}) => {
  let router = useRoutes(routes);
  const current_route = router.props.children.type.name
  console.log(current_route)
  if (current_route == "Careers") {
    console.log("hidden")
    setNavBarHidden(true)
  } else {
    setNavBarHidden(false)
  }
  console.log();
  return <>{router}</>;
};